

import React, { useEffect, useState } from 'react'
import datasupport1 from '../OracleManagedServices/Managedimages/SupportServices.jpeg'
import '../OracleManagedServices/SupportServices.css'
import ContactUsPopup from '../Popup';
import { Link } from 'react-router-dom';

export default function SupportServices() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);

  const openPopup = () => {
      setIsPopupOpen(true);
      setSubmitMessage(null); // Reset submit message on popup open
  };

  const closePopup = (message) => {
      setIsPopupOpen(false);
      setSubmitMessage(message); // Set the submit message in Forp
      console.log("Submitted message:", message); // Log the submitted message
  };

  useEffect(() => {
      if (submitMessage) {
          const timer = setTimeout(() => {
              setSubmitMessage(null);
          }, 5000); // Remove message after 5 seconds
          return () => clearTimeout(timer);
      }
  }, [submitMessage]);

  const isSuccessMessage = submitMessage && !submitMessage.includes('Failed');
  console.log("Is success message:", isSuccessMessage); // Log the success message check
  return (
    <div>
      <div className="bg">
        <div className="body">

          <div className="image-container">
                    <img src={datasupport1} className="img-fluid" alt="Technology" />
                    <div className="overlay"></div>
                    <div className="txt" style={{textAlign:'left'}}>
                        <h2 className='text-xl text-lg-left text-xl-left '>Database Support Services</h2>
                        <p className='Hp text-lg-left text-xl-left '>Your Data, Our Expertise</p>
                        <div className="d-flex justify-content-center">
                            {!isPopupOpen && (
                                <div className='d-flex justify-left'>
                                    <button className="btn btn-outline-light w-auto" onClick={openPopup}>Schedule a Free Consultation!</button>
                                    {submitMessage && (
                                        <div className={`submit-message ${isSuccessMessage ? 'success' : 'error'}`}>
                                            {submitMessage}
                                        </div>
                                    )}
                                </div>
                            )}
                            <ContactUsPopup isOpen={isPopupOpen} onClose={closePopup} />
                        </div>
                    </div>
                </div>
        </div>
        <div >
          
            <p className="parasR2">At Enormous IT, we recognize that a robust database environment is crucial for the efficient operation of any organization. Our Database Support Services are designed to ensure your databases are secure, reliable, and optimized for performance.</p>
          
          <div className='marourR2' >
            <p className='headchooseR2'>Our Comprehensive Offerings</p>
          </div>
          <div className="m1R1">
            <div className="cardsdtaR2 ">
              <li>
                <strong>Database Administration:</strong>
                <span> Our expert database administrators (DBAs) provide comprehensive management services, including installation, configuration,
                     and ongoing maintenance. We ensure your databases are set up for optimal performance and security.</span>
              </li>
            </div>
            <div className="cardsdtaR2">
              <li>
                <strong>Performance Tuning:</strong>
                <span> We analyze your database systems to identify performance bottlenecks and implement targeted optimizations. Our goal is to 
                    enhance query performance and overall responsiveness, ensuring your applications run smoothly.</span>
              </li>
            </div>
            <div className="cardsdtaR2 ">
              <li>
                <strong>Backup and Recovery:</strong>
                <span> Data integrity is paramount. We implement robust backup strategies and disaster recovery solutions to safeguard your data.
                     Our team ensures that your databases are backed up regularly and can be restored quickly in case of data loss.</span>
              </li>
            </div>
            <div className="cardsdtaR2">
              <li>
                <strong>Security Management:</strong>
                <span> Protecting your sensitive data is our priority. We offer comprehensive security assessments, access control management, and
                     vulnerability patching to ensure your databases are secure against potential threats.</span>
              </li>
            </div>
         
            <div className="cardsdtaR2 ">
              <li>
                <strong>Upgrades and Migrations: </strong>
                <span>Whether you’re looking to upgrade your database software or migrate to a new platform, we provide seamless support throughout the process. Our team minimizes downtime and ensures a smooth transition.</span>
              </li>
            </div>
            <div className="cardsdtaR2">
              <li>
                <strong>24/7 Monitoring and Support:</strong>
                <span>Our dedicated support team is available around the clock to monitor your database systems. We provide real-time alerts for any potential issues and offer prompt resolutions to keep your databases running optimally.</span>
              </li>
            </div>
          </div>
          <div>
            <p className="headchooseR2">Why Choose Enormous IT?</p>
            <div className="disgR2">
              <div className="fontszR2 clrbgR2 pdgR2">
                <li>
                  <strong>Expert Team:</strong>
                  <span>Our experienced DBAs have a wealth of knowledge across various database platforms, including Oracle, SQL Server, MySQL, and more.</span>
                </li>
                <li>
                  <strong>Tailored Solutions:</strong>
                  <span>We understand that every organization has unique database needs. Our services are customized to align with your specific requirements and business goals.</span>
                </li>
                <li>
                  <strong>Proven Expertise:</strong>
                  <span>With a history of successful database management projects, we have consistently delivered measurable improvements in performance, security, and reliability for our clients.</span>
                </li>
                <li>
                  <strong>Holistic IT Services:</strong>
                  <span>Enormous IT offers a full range of IT services, allowing you to partner with a single provider for all your technology needs, from cloud solutions to performance tuning.</span>
                </li>
              </div>
            </div>
            <div>
              <p className="headchooseR2">Contact Us Today!</p>
              <p className="parasR2 parasR1 disgR2  " style={{marginBottom:'15px'}}>Ready to elevate your database management? Partner with Enormous IT for comprehensive Database Support Services. 
              <Link className="mm" to="/Contactus" style={{textDecoration:'none'}}> {" "}Contact us {" "} </Link>
               today to discover how we can enhance the performance and security of your database systems!</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
