import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
// import Rou from "./Rmain.jpeg";
// import Chat from "./Chat.js";
// import PPT1 from "./ppt/ppt5.jpg";

import './Resources.css';
import ContactUsPopup from '../Popup'

import cs1 from './imgrrr/r2.jpeg';
import cs2 from './imgrrr/r3.jpeg';
import cs3 from './imgrrr/r4.jpeg';
import cs4 from './imgrrr/r5.jpeg';
import Rou from './imgrrr/r6.jpeg';
// import Footer from "../Footer";

// import cs1 from "./1.png";
// import cs2 from "./2.png";
// import cs3 from "./3.png";
// import cs4 from "./4.png";
// import { jsPDF } from "jspdf";


const Resources = () => {
  //   const downloadPdf = () => {
  //     // Create a new PDF document
  //     const doc = new jsPDF();

  //     // Set font size and style
  //     doc.setFontSize(12);
  //     doc.setFont("helvetica", "normal");

  //     // Define the text to be added
  //     const text =
  //       "A case study proves how your product has helped other companies by demonstrating real-life results. Not only that, but marketing case studies with solutions typically contain quotes from the customer. This means that they’re not just ads where you praise your own product. Rather, other companies are praising your company — and there’s no stronger marketing material than a verbal recommendation or testimonial.These marketing case studies with solutions show the value proposition of each product. They also show how each company benefited in both the short and long term using quantitative data. In other words, you don’t get just nice statements, like “this company helped us a lot.” You see actual change within the firm through numbers and figures.";

  //     // Page margins and dimensions
  //     const margin = 10; // Margin from left and right
  //     const pageWidth = doc.internal.pageSize.getWidth(); // Page width
  //     const lineHeght = 10; // Line height

  //     // Split the text into lines that fit within the page width
  //     const splitText = doc.splitTextToSize(text, pageWidth - 2 * margin);

  //     // Starting coordinates for text
  //     let x = margin;
  //     let y = margin;

  //     // Add each line to the PDF
  //     splitText.forEach((line) => {
  //       if (y + lineHeight > doc.internal.pageSize.height - margin) {
  //         // Add a new page if needed
  //         doc.addPage();
  //         y = margin; // Reset y position for the new page
  //       }
  //       doc.text(line, x, y); // Add text line
  //       y += lineHeight; // Move to the next line
  //     });

  //     // Save the PDF with a specific name
  //     doc.save("SampleDocument.pdf");
  //   };


  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [submitMessage, setSubmitMessage] = useState(null);

  const openPopup = () => {
      setIsPopupOpen(true);
      setSubmitMessage(null); // Reset submit message on popup open
  };

  const closePopup = (message) => {
      setIsPopupOpen(false);
      setSubmitMessage(message); // Set the submit message in Forp
      console.log("Submitted message:", message); // Log the submitted message
  };

  useEffect(() => {
      if (submitMessage) {
          const timer = setTimeout(() => {
              setSubmitMessage(null);
          }, 5000); // Remove message after 5 seconds
          return () => clearTimeout(timer);
      }
  }, [submitMessage]);

  const isSuccessMessage = submitMessage && !submitMessage.includes('Failed');
  console.log("Is success message:", isSuccessMessage); // Log the success message check

  return (
    <div className="body1str" >
      <div className="Ai-card">
        {/* <div className="image-containerp position-relative">
          <img src={Rou} className="img-fluidpz" alt="Technology" />
          <div className="overlayp position-absolutep w-100 h-100 d-flex flex-column justify-content-center ">
            <div className="txt text-center hfssr1">
              <p>Resources</p>

              <button
                type="button"
                className="btn1ssr1 btn-outline-lightpssr1 btntxtszssr1"
              >
                Schedule a Free Consultation
              </button>
            </div>
          </div>
        </div> */}
        <div className="image-container">
                    <img src={Rou} className="img-fluid" alt="Technology" />
                    <div className="overlay"></div>
                    <div className="txt">
                        <h2 className='text-xl text-xl-center text-center'>Resources</h2>
                        {/* <p className='Hp text-xl-left text-left'>Pioneering Tomorrow's Solutions Today</p> */}
                        <div className="d-flex justify-content-center">
                            {!isPopupOpen && (
                                <div className='d-flex justify-content-center'>
                                    <button className="btn btn-outline-light w-auto" onClick={openPopup}>Schedule a Free Consultation!</button>
                                    {submitMessage && (
                                        <div className={`submit-message ${isSuccessMessage ? 'success' : 'error'}`}>
                                            {submitMessage}
                                        </div>
                                    )}
                                </div>
                            )}
                            <ContactUsPopup isOpen={isPopupOpen} onClose={closePopup} />
                        </div>
                    </div>
                </div>
        {/* <Chat /> */}
        <p className="text-center headssr1">Case Study</p>
        <div  >
          <div className="shreshtha1">

            <div className="col-md-4 col-sm-6 mb-4 text-center">
              <img src={cs4} className="img-fluid shrew " alt="CS1" />
              <div className="mt-2">

                {/* <button className="btnssr1 btn-primary sstr12 class2">
                  <a
                    style={{ textDecoration: "none", color: "white" }}
                    href="/Enormous _TS_012115.pdf"
                    target="_black"
                  >
                    Download
                  </a>
                </button> */}
                {/* <button
  className="btnssr1 btn-primary sstr12 class2"
  onClick={() => window.open('/Enormous_TS_012115.pdf', '_blank')}
  style={{ backgroundColor: '#007BFF', color: 'white', border: 'none', padding: '10px 20px', cursor: 'pointer' }}
>
  Download

</button> */}


<a
  className="btnssr1 btn-primary sstr12 class2"
  href="/Enormous _TS_012115.pdf"
  target="_blank"
  rel="noopener noreferrer"
  style={{ display: 'inline-block', textDecoration: 'none', color: 'white', backgroundColor: '#007BFF', padding: '10px 20px', borderRadius: '4px', textAlign: 'center' }}
>
  Download
</a>

              </div>
            </div>
            <div className="col-md-4 col-sm-6 mb-4 text-center" >
              <img src={cs1}   className="img-fluid shrew" alt="CS2" />
              <div className="mt-2">

                {/* <button className="btnssr1 btn-primary sstr12 class2">
                  <a
                    style={{ textDecoration: "none", color: "white",}}
                    href="/Enormous OBI_012115.pdf"
                    target="_black"
                  >
                    Download
                  </a>
                </button> */}
<a
  className="btnssr1 btn-primary sstr12 class2"
  href="/Enormous OBI_012115.pdf"
  target="_blank"
  rel="noopener noreferrer"
  style={{ display: 'inline-block', textDecoration: 'none', color: 'white', backgroundColor: '#007BFF', padding: '10px 20px', borderRadius: '4px', textAlign: 'center' }}
>
  Download
</a>
              </div>
            </div>
          </div>
          <div className="shreshtha1">
            <div className="col-md-4 col-sm-6 mb-4 text-center">
              <img src={cs2} className="img-fluid shrew" alt="CS3" />
              <div className="mt-2">
                {/* <button className="btnssr1 btn-primary sstr12 class2">
                  <a
                    style={{ textDecoration: "none", color: "white" }}
                    href="/Enormous _OA_CS_012115_.pdf"
                    target="_black"
                  >
                    Download
                  </a>
                </button> */}

                <a
  className="btnssr1 btn-primary sstr12 class2"
  href="/Enormous _OA_CS_012115_.pdf"
  target="_blank"
  rel="noopener noreferrer"
  style={{ display: 'inline-block', textDecoration: 'none', color: 'white', backgroundColor: '#007BFF', padding: '10px 20px', borderRadius: '4px', textAlign: 'center' }}
>
  Download
</a>
     
              </div>
            </div>
            <div className="col-md-4 col-sm-6 mb-4 text-center " >
              <img src={cs3}   className="img-fluid shrew" alt="CS3" />
              <div className="mt-2">
                {/* <button className="btnssr1 btn-primary sstr12 class2">
                  <a
                    style={{ textDecoration: "none", color: "white" ,  }}
                    href="/Enormous _OA_CT_012115_.pdf"
                    target="_black"
                  >
                    Download
                  </a>
                </button> */}
                <a
  className="btnssr1 btn-primary sstr12 class2"
  href="/Enormous _OA_CT_012115_.pdf"
  target="_blank"
  rel="noopener noreferrer"
  style={{ display: 'inline-block', textDecoration: 'none', color: 'white', backgroundColor: '#007BFF', padding: '10px 20px', borderRadius: '4px', textAlign: 'center' }}
>
  Download
</a>
              </div>
            </div>
          </div>
        </div>


      </div>
       
    </div>
    
  );
};

export default Resources;