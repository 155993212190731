import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "bootstrap/dist/css/bootstrap.min.css";
import emailjs from "emailjs-com";
import "./Popup.css";
import ServicesDropdown from "./Services.js"; // Update the import path if needed
import Filter from "bad-words";

// Create a new instance of the filter
const filter = new Filter();

// Define lists of disallowed names
const disallowedWords = [
  // Fruits
  "apple", "banana", "orange", "strawberry", "grape", "pineapple", "mango", "blueberry", "raspberry", "blackberry",
  "pear", "peach", "plum", "kiwi", "papaya", "watermelon", "melon", "cantaloupe", "nectarine", "apricot", "cherry",
  "grapefruit", "lime", "lemon", "pomegranate", "coconut", "fig", "date", "tangerine", "mandarin", "jackfruit",

  // Vegetables
  "carrot", "potato", "onion", "garlic", "spinach", "broccoli", "cauliflower", "pepper", "cucumber", "tomato", "lettuce",
  "zucchini", "eggplant", "corn", "peas", "green bean", "radish", "celery", "beet", "turnip", "asparagus", "pumpkin",
  "sweet potato", "squash", "kale", "brussels sprout", "artichoke", "chard", "parsnip", "leek", "okra", "rutabaga",

  // Animals
  "dog", "cat", "mouse", "rat", "rabbit", "hamster", "guinea pig", "ferret", "squirrel", "horse", "cow", "sheep",
  "goat", "pig", "chicken", "duck", "turkey", "goose", "camel", "lion", "tiger", "bear", "elephant", "giraffe",
  "zebra", "hippopotamus", "rhinoceros", "panda", "koala", "kangaroo", "monkey", "gorilla", "chimpanzee", "sloth",
  "penguin", "seal", "otter", "whale", "dolphin", "shark", "octopus", "jellyfish", "crab", "lobster", "shrimp",'hibye','bad','waste','stupid','dirty','dirt','rascal','idiot'
];

const validateInput = (input) => {
  // Regular expression to match any special character
  const specialCharRegex = /[^a-zA-Z0-9 ]/;

  // Check for special characters
  if (specialCharRegex.test(input)) {
    return 'Special characters are not allowed.';
  }

  // Check for abusive words using bad-words filter
  if (filter.isProfane(input)) {
    return 'Profane language is not allowed.';
  }

  // Check for disallowed names
  const lowerCaseInput = input.toLowerCase();
  if (disallowedWords.some(word => lowerCaseInput.includes(word))) {
    return 'Input contains disallowed names.';
  }

  return '';
};

function CustomerForm({ isOpen, onClose }) {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    companyName: "",
    message: "",
    service: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    const validationError = name === "message" ? validateInput(value) : "";
    setFormData({ ...formData, [name]: value });
    if (validationError) {
      setErrors({ ...errors, [name]: validationError });
    } else {
      setErrors({ ...errors, [name]: "" });
    }
  };

  const handlePhoneChange = (value) => {
    setFormData({ ...formData, phone: value });
  };

  const handleServiceChange = (value) => {
    setFormData({ ...formData, service: value });
  };

  const validateForm = () => {
    const newErrors = {};

    // Name validation
    if (!/^[a-zA-Z. ]+$/.test(formData.name)) {
      newErrors.name = "Name should only contain alphabets and periods.";
    }

    // Email validation
    if (
      !/^[\w.%+-]+@(gmail\.com|outlook\.com|yahoo\.com)$/.test(formData.email)
    ) {
      newErrors.email =
        "Email should be a valid address from gmail.com, outlook.com, or yahoo.com.";
    }

    // Phone validation (international format)
    if (!/^\+?[1-9]\d{1,14}$/.test(formData.phone)) {
      newErrors.phone =
        "Mobile number should be a valid international number, e.g., +1234567890.";
    }

    // Message validation
    const messageError = validateInput(formData.message);
    if (messageError) {
      newErrors.message = messageError;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const templateParams = {
        from_name: formData.name,
        from_email: formData.email,
        from_mobile: formData.phone,
        from_company: formData.companyName,
        from_message: formData.message,
        from_service: formData.service,
      };

      console.log("Sending initial email with params:", templateParams);

      emailjs
        .send(
          "service_zi8xtlb",
          "template_v933c6p",
          templateParams,
          "UpSr0sdz75bSvFIZq"
        )
        .then(
          (response) => {
            console.log("Initial email sent successfully!", response.status, response.text);
            sendThankYouEmail(formData, onClose);
          },
          (error) => {
            console.error("Failed to send initial email. Error:", error.text);
            onClose("Failed to send message. Please try again.");
          }
        );
    }
  };

  const sendThankYouEmail = (formData, onClose) => {
    const thankYouTemplateParams = {
      to_name: formData.name,
      to_email: formData.email,
      // Include any other parameters if your template requires them
    };
  
    emailjs.send(
      "service_e3wxd23", // Replace with your service ID
      "template_poqspvt", // Replace with your template ID
      thankYouTemplateParams,
      "UcsdwIxhpf-3b6lQ5" // Replace with your user ID
    )
    .then(
      (thankYouResponse) => {
        console.log("Thank you email sent successfully!", thankYouResponse.status, thankYouResponse.text);
        onClose("Thanks for Reaching Out! Your Consultation Slot will be Emailed to you.");
      },
      (thankYouError) => {
        console.error("Failed to send thank you email. Error:", thankYouError);
        onClose("Failed to send thank you email. Please try again.");
      }
    );
  };

  return (
    <Modal show={isOpen} onHide={() => onClose(null)}>
      <Modal.Header closeButton>
        <Modal.Title>Schedule Here!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="name"
              placeholder="Full Name"
              value={formData.name}
              onChange={handleChange}
              isInvalid={!!errors.name}
              required
            />
            <Form.Control.Feedback type="invalid">
              {errors.name}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
              isInvalid={!!errors.email}
              required
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formPhone">
            <Form.Label>Mobile</Form.Label>
            <PhoneInput
              className="phone-input"
              country={"in"}
              value={formData.phone}
              placeholder="Mobile Number"
              onChange={handlePhoneChange}
              inputProps={{
                name: "phone",
                required: true,
                autoFocus: true,
              }}
            />
            {errors.phone && <p className="text-danger">{errors.phone}</p>}
          </Form.Group>

          <Form.Group controlId="formCompanyName">
            <Form.Label>Company Name</Form.Label>
            <Form.Control
              type="text"
              name="companyName"
              placeholder="Company Name"
              value={formData.companyName}
              onChange={handleChange}
              required
            />
          </Form.Group>

          <Form.Group controlId="formService">
            <Form.Label>Service</Form.Label>
            <ServicesDropdown
              name="service"
              selectedService={formData.service}
              onChange={handleServiceChange}
            />
          </Form.Group>

          <Form.Group controlId="formMessage">
            <Form.Label>Message</Form.Label>
            <Form.Control
              as="textarea"
              name="message"
              placeholder="Required Services and Reason for Contact"
              value={formData.message}
              onChange={handleChange}
              isInvalid={!!errors.message}
              rows={3}
              required
            />
            <Form.Control.Feedback type="invalid">
              {errors.message}
            </Form.Control.Feedback>
          </Form.Group>
          <div className="d-flex justify-content-center">
            <input
              className="btn-primary sbuttong w-25"
              type="submit"
              value="Submit"
            />
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
}

export default CustomerForm;
