
import React, { useEffect, useState } from 'react';
import '../AddOrServices/OracleIntegration.css';

import int from '../AddOrServices/AddOrSimages/OracleIntegration.jpeg';
import ContactUsPopup from '../Popup';

export default function OracleIntegration() {
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [submitMessage, setSubmitMessage] = useState(null);

    const openPopup = () => {
        setIsPopupOpen(true);
        setSubmitMessage(null); // Reset submit message on popup open
    };

    const closePopup = (message) => {
        setIsPopupOpen(false);
        setSubmitMessage(message); // Set the submit message in Forp
        console.log("Submitted message:", message); // Log the submitted message
    };

    useEffect(() => {
        if (submitMessage) {
            const timer = setTimeout(() => {
                setSubmitMessage(null);
            }, 5000); // Remove message after 5 seconds
            return () => clearTimeout(timer);
        }
    }, [submitMessage]);

    const isSuccessMessage = submitMessage && !submitMessage.includes('Failed');
    console.log("Is success message:", isSuccessMessage); // Log the success message check
    return (
        <div className="bgRr">
       

            <div className="image-container">
                    <img src={int} className="img-fluid" alt="Technology" />
                    <div className="overlay"></div>
                    <div className="txt">
                        <h2 className='text-xl text-lg-center text-xl-left text-center'>Oracle Integration</h2>
                        <p className='Hp text-lg-center text-xl-left text-center'>Unified Systems, Superior Performance.</p>
                        <div className="d-flex justify-content-center">
                            {!isPopupOpen && (
                                <div>
                                    <button className="btn btn-outline-light w-auto" onClick={openPopup}>Schedule a Free Consultation!</button>
                                    {submitMessage && (
                                        <div className={`submit-message ${isSuccessMessage ? 'success' : 'error'}`}>
                                            {submitMessage}
                                        </div>
                                    )}
                                </div>
                            )}
                            <ContactUsPopup isOpen={isPopupOpen} onClose={closePopup} />
                        </div>
                    </div>
                </div>

            <div className="mars1Rr">
                <div className="fontszRr">
                    <p className="paraRr">Enormous IT’s Oracle Integration services provide businesses with the tools needed to connect applications, automate workflows, and drive digital transformation. Our solutions enable organizations to synchronize data across systems and improve operational efficiency.</p>
                </div>
                <div className="headingssstRr">
                    <p>Key Features</p>
                </div>
           
                <div className="m1Rr">

                    <div className='flex83Raj '  style={{paddingLeft:'15px',gap:'10px'}}>
                                        <div className=" mb-4">
                                            <div className='card12raj3  cb2Raj  padRaj  T8Raj'>
                                                <h4 className=" text-center "  style={{fontFamily:"Franklin Gothic Medium"}}><strong>Pre-built Connectors</strong></h4>
                                                <p >
                                                Access a comprehensive library of pre-built connectors for Oracle
                                                 and non-Oracle applications, allowing for rapid integration with minimal coding.
                                                </p>

                                            </div>
                                        </div>

                                        <div className=" mb-4">
                                            <div className='card12raj3  cb2Raj  padRaj  T8Raj'>
                                                <h4 className=" text-center "  style={{fontFamily:"Franklin Gothic Medium"}}><strong>	Workflow Automation</strong></h4>
                                                <p >
                                                Design custom workflows that automate business processes, improving efficiency and reducing manual errors.
                                                </p>

                                            </div>
                                        </div>
                                    </div>

                                    <div className='flex83Raj ' style={{paddingLeft:'15px',gap:'10px'}}>
                                        <div className=" mb-4">
                                            <div className='card12raj3  cb2Raj  padRaj  T8Raj'>
                                                <h4 className=" text-center "  style={{fontFamily:"Franklin Gothic Medium"}}><strong>Real-Time Synchronization</strong></h4>
                                                <p >
                                                Ensure that data is cocardsexansistently updated across all platforms, enhancing decision-making capabilities.
                                                </p>

                                            </div>
                                        </div>

                                        <div className=" mb-4">
                                            <div className='card12raj3  cb2Raj  padRaj  T8Raj'>
                                                <h4 className=" text-center "  style={{fontFamily:"Franklin Gothic Medium"}}><strong>Analytics and Monitoring</strong></h4>
                                                <p >
                                                Utilize built-in analytics tools to monitor integration performance and gain insights into process efficiency.
                                                </p>

                                            </div>
                                        </div>
                                    </div>

                                  

                   
                 
                </div>
                {/* <div className="m1Rr">
                    <div className="cardsexaRr flxRr">
                        <li>  <span className='strongrrRr'>Real-Time Synchronization:</span>
                        <span className='paraRr'>Ensure that data is cocardsexansistently updated across all platforms, enhancing decision-making capabilities.</span></li>
                    </div>
                    <div className="cardsexaRr flxRr">
                        <li><span className='strongrrRr'>Analytics and Monitoring:</span>
                             <span className='paraRr'>Utilize built-in analytics tools to monitor integration performance and gain insights into process efficiency.</span></li>
                    </div>
                </div> */}
                <div className="headchooseRr">
                    <p>Benefits</p>
                </div>
                <div className="paraRr">
                    <li><strong>Streamlined Processes: </strong>Eliminate inefficiencies through automated workflows that facilitate smooth data movement.</li>
                    <li><strong>Enhanced Collaboration: </strong>Foster collaboration across departments and applications, improving overall organizational agility.</li>
                    <li><strong>Increased Visibility: </strong>Gain real-time visibility into integration performance, enabling timely adjustments and optimizations.</li>
                </div>
                <div >
                    <div className='headchooseRr'>
                    <p >ContactUs!</p>
                    </div>
                    <p className="paraRr parasR12" >To learn more about how our Oracle Integration services can enhance your business operations, please reach out at
                     <a className="decst"  style={{marginLeft:'10px'}} href="https://mail.google.com/mail/u/0/#inbox?compose=DmwnWsCcMvkSVZfrZzcCqQQtLpHRCvbhnxXvfPblfrzvRhgTCnkGLXPFzDmkxRSMSxjXxSgFqQvq">info@enormousit.com</a>. 
                    {" "} Our team is eager to help you achieve your integration goals!</p>
                </div>

           
            </div>
        </div>
    );
}



