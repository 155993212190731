import React from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';

// Client images
import vmwarelogo from '../images/vmware.png';
import oracle from '../images/Oracle.png';
import mojio from '../images/mojio.png';
import linkedin from '../images/linkedin.png';
import sherweb from '../images/sherweb.png';
import kfintech from '../images/kfintech.png';
import airbnb from '../images/airbnb.png';
import drdo from '../images/drdo_rci.png';
import bfil from '../images/bfil.png';
import goodyear from '../images/goodyear.png';
import hunterdouglas from '../images/hunterdouglas.png';
import stagwell from '../images/stagwell.png';
import otsuka from '../images/otsuka.png';
import rbl from '../images/rbl_finserve.png';
import tcil from '../images/tcil.png';



function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block",background:'black', borderRadius:'30px'}}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background:'black', borderRadius:'30px' }}
        onClick={onClick}
      />
    );
  }


  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        }
      },
      {
        breakpoint: 425,
        settings: {
          slidesToShow: 3,
        }
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

const ClientSlider = () => {
  return (
    <div>
    <div className='container'>
        <Slider {...settings}>
          <div className="card12">
            <img src={vmwarelogo} alt='VMware Logo' className="img-fluid" />
          </div>
          <div className="card12">
            <img src={oracle} alt='Oracle Logo' className="img-fluid" />
          </div>
          <div className="card12">
            <img src={mojio} alt='Mojio Logo' className="img-fluid" />
          </div>
          <div className="card12">
            <img src={linkedin} alt='LinkedIn Logo' className="img-fluid" />
          </div>
          <div className="card12">
            <img src={sherweb} alt='Sherweb Logo' className="img-fluid" />
          </div>
          <div className="card12">
            <img src={kfintech} alt='KFintech Logo' className="img-fluid" />
          </div>
          <div className="card12">
            <img src={airbnb} alt='Airbnb Logo' className="img-fluid" />
          </div>
          <div className="card12">
            <img src={drdo} alt='DRDO Logo' className="img-fluid" />
          </div>
          <div className="card12">
            <img src={bfil} alt='BFIL Logo' className="img-fluid" />
          </div>
          <div className="card12">
            <img src={goodyear} alt='Goodyear Logo' className="img-fluid" />
          </div>
          <div className="card12">
            <img src={hunterdouglas} alt='Hunter Douglas Logo' className="img-fluid" />
          </div>
          <div className="card12">
            <img src={stagwell} alt='Stagwell Logo' className="img-fluid" />
          </div>
          <div className="card12">
            <img src={otsuka} alt='Otsuka Logo' className="img-fluid" />
          </div>
          <div className="card12">
            <img src={rbl} alt='RBL Logo' className="img-fluid" />
          </div>
          <div className="card12">
            <img src={tcil} alt='TCIL Logo' className="img-fluid" />
          </div>
        </Slider>
      </div>
      </div>
  )
}

export default ClientSlider;
